import React, { FC, FormEventHandler, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import parseHtml from 'html-react-parser'
import { Box, Card, Heading, Text } from 'rebass'
import fetch from 'cross-fetch'
import { Label, Input, Textarea } from '@rebass/forms'
import HomeLink from './HomeLink'
import { CompactCTAButton } from './CTAButton'
import SrOnly from '../ui/SrOnly'
import Modal from '../ui/Modal'
import Grid from '../ui/Grid'
import GridItem from '../ui/GridItem'
import useIntl from '../states/useIntl'

import successIconUrl from '../content/images/icons/contact_success.svg'

const CardBgContainer = styled(Card)`
  overflow: hidden;
  position: relative;
`

const CardContent = styled(Grid)`
  z-index: 3;
  position: relative;
`

const CardBg = styled('div')`
  z-index: 2;
  position: absolute;
  width: 315px;
  height: 315px;
  left: 0;
  top: -64px;
  filter: blur(100px);
  transform: rotate(97deg);
  background: linear-gradient(137deg, rgba(246, 148, 29, 0.2) 19.38%, rgba(246, 42, 29, 0.2) 81.69%)
`

type FormState = 'initial' | 'submitting' | 'rejected' | 'submitted'

const ContactForm: FC = () => {
  const { t } = useIntl()
  const [formState, setFormState] = useState<FormState>('initial')

  const isDisabled = formState === 'submitting' || formState === 'submitted'

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
    e.preventDefault()

    setFormState('submitting')

    try {
      const res = await fetch(e.currentTarget.action, {
        method: e.currentTarget.method,
        body: new FormData(e.currentTarget),
        headers: {
          Accept: 'application/json',
        },
      })

      if (res.status >= 400) {
        throw new Error()
      }

      setFormState('submitted')
    } catch (error) {
      setFormState('rejected')
    }
  }, [])

  return (
    <Box
      my={-2}
      fontSize={0}
    >
      <form
        method="POST"
        onSubmit={handleSubmit}
        action={process.env.CONTACT_FORM_ENDPOINT}
      >
        <SrOnly>
          <Label
            mt={3}
            mb={1}
            htmlFor="name"
          >
            {t('contactForm.name')}
          </Label>
        </SrOnly>
        <Input
          my={3}
          required
          id="name"
          name="name"
          type="text"
          disabled={isDisabled}
          placeholder={t('contactForm.name')}
        />

        <SrOnly>
          <Label
            mt={3}
            mb={1}
            htmlFor="email"
          >
            {t('contactForm.email')}
          </Label>
        </SrOnly>
        <Input
          my={3}
          required
          id="email"
          name="email"
          type="email"
          disabled={isDisabled}
          placeholder={t('contactForm.email')}
        />

        <SrOnly>
          <Label
            mt={3}
            mb={1}
            htmlFor="phone"
          >
            {t('contactForm.phone')}
          </Label>
        </SrOnly>
        <Input
          my={3}
          id="phone"
          name="phone"
          type="tel"
          disabled={isDisabled}
          placeholder={t('contactForm.phone')}
        />

        <SrOnly>
          <Label
            mt={3}
            mb={1}
            htmlFor="company"
          >
            {t('contactForm.company')}
          </Label>
        </SrOnly>
        <Input
          my={3}
          id="company"
          name="company"
          type="text"
          disabled={isDisabled}
          placeholder={t('contactForm.company')}
        />

        <SrOnly>
          <Label
            mt={3}
            mb={1}
            htmlFor="message"
          >
            {t('contactForm.message')}
          </Label>
        </SrOnly>
        <Textarea
          my={3}
          rows={6}
          required
          id="message"
          name="message"
          disabled={isDisabled}
          placeholder={t('contactForm.message')}
        />

        {
          formState === 'rejected'
            ? (
              <Text my={3}>
                {t('contactForm.rejected')}
              </Text>
            ) : null
        }

        <CompactCTAButton
          containerProps={{ my: 3 }}
          type="submit"
          disabled={isDisabled}
        >
          {t('contactForm.submit')}
        </CompactCTAButton>
      </form>

      {
        formState === 'submitted'
          ? (
            <Modal>
              <CardBgContainer
                py={[3, 4]}
                px={[4, 5]}
                minWidth={[280, '80%', 690]}
              >
                <CardContent mx={-4}>
                  <GridItem
                    px={4}
                    width="185px"
                    display={['none', 'none', 'block']}
                  >
                    <Box
                      alt=""
                      as="img"
                      width="100%"
                      src={successIconUrl}
                    />
                  </GridItem>
                  <GridItem
                    px={4}
                    my={-3}
                    flex={1}
                  >
                    <Heading
                      my={3}
                      as="h3"
                      fontSize={3}
                    >
                      {t('contactForm.successTitle')}
                    </Heading>
                    <Text
                      my={3}
                      fontSize={0}
                    >
                      {
                        parseHtml(
                          t('contactForm.successMessage'),
                        )
                      }
                    </Text>
                    <HomeLink>
                      <CompactCTAButton
                        as="div"
                        containerProps={{ my: 3 }}
                      >
                        {t('contactForm.successAction')}
                      </CompactCTAButton>
                    </HomeLink>
                  </GridItem>
                </CardContent>
                <CardBg />
              </CardBgContainer>
            </Modal>
          ) : null
      }
    </Box>
  )
}

export default ContactForm
