import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import { GlobalPageContext } from '../types'
import { ContactQuery } from '../../graphql-types'
import ContainerFixed from '../ui/ContainerFixed'
import Grid from '../ui/Grid'
import GridItem from '../ui/GridItem'
import PageHeading from '../components/PageHeading'
import ContactForm from '../components/ContactForm'
import ContactInfo from '../components/ContactInfo'
import PageContainer from '../components/PageContainer'
import createPageContainerProps from '../createPageContainerProps'

type Props = PageProps<ContactQuery, GlobalPageContext>

const ContactPage: FC<Props> = ({ data, pageContext }) => {
  const { seoTranslations, commonTranslations, contactTranslations, contactInfo } = data
  const t = { ...seoTranslations!.json!, ...commonTranslations!.json!, ...contactTranslations!.json }

  return (
    <PageContainer
      translations={t}
      {...createPageContainerProps(data, pageContext)}
    >
      <ContainerFixed pb={[3, 3, '200px']}>
        <PageHeading
          pt={4}
          pb={2}
        >
          {seoTranslations?.json?.meta?.title}
        </PageHeading>
        <Grid mx={[-3, -3, -3, -5]}>
          <GridItem
            py={4}
            px={[3, 3, 3, 5]}
            as="section"
            width={[1, 1, 1/2]}
          >
            <ContactForm />
          </GridItem>
          <GridItem
            py={4}
            px={[3, 3, 3, 5]}
            as="section"
            width={[1, 1, 1/2]}
          >
            <ContactInfo
              contact={contactInfo?.siteMetadata?.contact}
            />
          </GridItem>
        </Grid>
      </ContainerFixed>
    </PageContainer>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query Contact($pathRegex: String!, $lang: String!, $pageId: String!) {
    ...PageData
    ...ContactInfo

    contactTranslations: file(
      name: { regex: $pathRegex }
      extension: { eq: "json" }
      absolutePath: { regex: "/translations/" }
    ) {
      json: childTranslationsJson {
        contactForm {
          name
          email
          phone
          company
          message
          submit
          rejected
          successTitle
          successAction
          successMessage
        }
      }
    }
  }
`
