import React, { FC } from 'react'
import { Flex, Box } from 'rebass'
import List from '../ui/List'
import ListItem from '../ui/ListItem'
import LinkExternal from '../ui/LinkExternal'
import { ContactInfoFragment } from '../../graphql-types'

import mailIconUrl from '../content/images/icons/mail.svg'
import phoneIconUrl from '../content/images/icons/phone.svg'
import pinIconUrl from '../content/images/icons/pin.svg'

type Props = NonNullable<Required<ContactInfoFragment['contactInfo']>>['siteMetadata']

const ContactInfo: FC<Props> = ({ contact }) => {
  return (
    <List
      my={-2}
      fontSize={0}
    >
      <ListItem py={2}>
        <Flex flexDirection="row">
          <Box
            mr={3}
            as="img"
            alt=""
            src={mailIconUrl}
          />
          <LinkExternal
            color="inherit"
            href={`mailto:${contact?.machine?.email}`}
          >
            {contact?.display?.email}
          </LinkExternal>
        </Flex>
      </ListItem>
      <ListItem py={2}>
        <Flex flexDirection="row">
          <Box
            mr={3}
            as="img"
            alt=""
            src={phoneIconUrl}
          />
          <LinkExternal
            color="inherit"
            href={`tel:${contact?.machine?.phone}`}
          >
            {contact?.display?.phone}
          </LinkExternal>
        </Flex>
      </ListItem>
      <ListItem py={2}>
        <Flex flexDirection="row">
          <Box
            mr={3}
            as="img"
            alt=""
            src={pinIconUrl}
          />
          <LinkExternal
            color="inherit"
            href={`https://www.google.com/maps?q=${encodeURIComponent('1066 Budapest, Teréz krt. 20., II/1.')}`}
          >
            1066 Budapest, Teréz krt. 20., II/1.
          </LinkExternal>
        </Flex>
      </ListItem>
    </List>
  )
}

export default ContactInfo
