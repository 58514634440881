import React, { FC, ComponentProps as CP } from 'react'
import { Link } from 'rebass'

type Props = CP<typeof Link> & {
  title?: string | null
}

const LinkExternal: FC<Props> = ({ css, ...props }) => (
  <Link
    target="_blank"
    {...props}
    rel="noopener noreferrer"
  />
)

export default LinkExternal
