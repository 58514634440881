import React, { FC } from 'react'
import { DefaultTheme } from 'styled-components'
import styled from '@emotion/styled'

const Overlay = styled('div')<{}, DefaultTheme>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndices![2]};
  background-color: ${props => props.theme.colors.overlay};
`

const Content = styled('div')<{}, DefaultTheme>`
  position: fixed;
  top: 18%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  z-index: ${props => props.theme.zIndices![3]};
`

const Modal: FC = ({ children }) => {
  return (
    <div>
      <Content>
        {children}
      </Content>
      <Overlay />
    </div>
  )
}

export default Modal
